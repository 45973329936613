// Configuration docs:
// https://aws-amplify.github.io/docs/js/authentication#manual-setup

import axios from "axios";

export function getCognitoValues() {
    if(process.env.NODE_ENV === 'development') {
        return new Promise((resolve, ) => {
            resolve({data: {"VUE_APP_COGNITO_USERPOOL_ID": "us-east-2_KdUe6HsQB", "VUE_APP_COGNITO_CLIENT_ID": "6prb0de9o8s0e2dedn4p7upopj", "VUE_APP_COGNITO_APP_DOMAIN": "feature-16-fastapi.auth.us-east-2.amazoncognito.com"}})
        })
    }
    else {
        return axios.get( "/cognitoExports.json")
    }
}
