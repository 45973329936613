<template>
<h1>Hello!</h1>
</template>

<script>
export default {
  name: "HomePage"
}
</script>

<style scoped>

</style>