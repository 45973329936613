<template>
  <div class="main">
    <div v-if="userIsInAnyGroup">
      <button @click="incrementCount" ref="countButton">Loading...</button>
      <button @click="switchBackend" ref="backendButton">Switch backend (currently using lambda)</button>
    </div>
    <div v-else>
      Incorrect credentials
    </div>
    <router-view></router-view>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import axios from 'axios';

export default {
   name: 'LoginPage',
   props: {},

   components: {

   },

   data()
   {
      return {
        count: 0,
        backend_type: "lambda"
      };
   },
   created() {
      // redirect to user landing if authenticated.
      // we can't do this in per-route beforeEnter() as
      // on initial login the Cognito callbacks won't have happened yet. 
      if (this.signedIn)
      {
         this.$router.push({name: "HomePage"})
      }
   },
   mounted() {
      this.getCount()
   },
   computed:
   {
      // Map getters from store
      ...mapGetters([
         'signedIn',
         'userIsInAnyGroup',
      ]),
     backendButtonText() {
       return `Switch backend (currently using ${this.backend_type})`
     },
   },
   methods: {
     getCount() {
       axios.get(`/${this.backend_type}/getCounter`).then((res) => {
         this.count = res.data
         this.$refs.countButton.innerText = `Count: ${this.count.count}`
       })
     },
     incrementCount() {
       axios.post(`/${this.backend_type}/incrementCounter`).then((res) => {
         this.count = res.data
         this.$refs.countButton.innerText = `Count: ${this.count}`
       })
     },
     switchBackend() {
       this.backend_type = this.backend_type === "lambda" ? "docker" : "lambda"
       this.$refs.backendButton.innerText = this.backendButtonText
     }
   },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
   margin: 10px;
   color: #42b983;
}
</style>
