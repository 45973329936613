import { createApp, h } from "vue";
// import App from './App.vue'
import axios from "axios";
// import router from './router';
import Amplify from '@aws-amplify/core'
import { Auth } from '@aws-amplify/auth'
import {getCognitoValues} from './awsconfig';
import {store} from './store.js';
import HomePage from "@/components/HomePage";
import LoginPage from "@/components/LoginPage";
import {createRouter, createWebHashHistory} from 'vue-router'
import App from "@/App";


getCognitoValues().then((response) => {
    let client_id
    let userpool_id
    let domain
    userpool_id = response?.data?.VUE_APP_COGNITO_USERPOOL_ID
    client_id = response?.data?.VUE_APP_COGNITO_CLIENT_ID
    domain = response?.data?.VUE_APP_COGNITO_APP_DOMAIN

    Auth.configure( {
        Auth: {
            region: process.env.VUE_APP_AWS_REGION,
            userPoolId: userpool_id,
            userPoolWebClientId: client_id,
            oauth: {
                domain: domain,
                scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
                redirectSignIn: process.env.VUE_APP_BASE_URL,
                redirectSignOut: process.env.VUE_APP_BASE_URL,
                responseType: 'code',
            }
        }
    });

    Amplify.configure( {
        Auth: {
            region: process.env.VUE_APP_AWS_REGION,
            userPoolId: userpool_id,
            userPoolWebClientId: client_id,
            oauth: {
                domain: domain,
                scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
                redirectSignIn: process.env.VUE_APP_BASE_URL,
                redirectSignOut: process.env.VUE_APP_BASE_URL,
                responseType: 'code',
            }
        }
    });
});


axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.interceptors.request.use(
    async config => {
        try {
            // Refresh token and get session; update state session
            const userSession = await Auth.currentSession();
            store.commit('setUserSession', userSession);
            if (userSession)
            {
                config.headers.Authorization = userSession.getIdToken().getJwtToken();
            }
        }
        catch (error) {
            console.log('Error setting Authorization header:', error);
            store.commit('clearUserSesion');
        }
        //console.log('Modified request config:', config);
        return config;

    },
    error => {
        Promise.reject(error)
    },
)


const routes = [
    { path: '/', component: LoginPage },
    { path: '/home', component: HomePage },
]

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHashHistory(),
    routes, // short for `routes: routes`
})

// 5. Create and mount the root instance.
const app = createApp({
    render: ()=>h(App)
})
// Make sure to _use_ the router instance to make the
// whole app router-aware.
app.use(router)
app.use(store)



app.mount('#app')
