<template>
  <div id="app">
    FastAPI Demo
    <div v-if="isReady" class="main-content">
      <div v-if="signedIn">
        <div id="signed-in-as" class="mb-1">
          <div>
            <span class="static-text"></span>
            <span class="user-email">{{ userEmail }}</span>
          </div>
          <b-link v-on:click="signOut" id="sign-out-button">Sign Out</b-link>
        </div>
        <div class="container">
          <router-view></router-view>
        </div>
      </div>

      <div v-else>
        <b-button v-on:click="signIn" class="signIn" id="sign-in-button">Sign In</b-button>
      </div>
    </div>
    <div v-else class="main-content">
      Loading ...
    </div>

    <footer>
      Build {{ buildCommitId }} {{ buildDateTime }}
    </footer>
  </div>
</template>

<script>
//import axios from 'axios';
import { mapGetters } from 'vuex';
import { Auth, Hub } from 'aws-amplify';


export default {
  name: 'app',

  data() {
    return {
      ready: false,
      buildDateTime: process.env.VUE_APP_BUILD_DATETIME,
      buildCommitId: process.env.VUE_APP_BUILD_COMMIT_SHA?.substr(0, 8),
    };
  },

  components: {
  },

  computed: {
    ...mapGetters(['signedIn', 'userEmail']),

    isReady() {
      return this.ready || this.signedIn
    }
  },

  beforeCreate()
  {
    // Listen to Amplify Hub auth events
    Hub.listen('auth', ({ payload: { event, data, message } }) => {
      console.log('Hub auth event:', event);
      switch(event)
      {
        case "signIn":
          this.$store.dispatch('fetchUserSession');
          break;

        case "cognitoHostedUI":
          // Because we're using Cognito hosted UI, this event is
          // emitted along with "signIn". We can ignore.
          break;

        case 'oAuthSignOut': // fall through
        case 'signOut':
          console.log('case', event);
          this.$store.commit('clearUserSession');
          break;

        case 'signIn_failure':          // fall through
        case 'cognitoHostedUI_failure': // fall through
        case 'customState_failure':
          console.log('Auth event failure:', event, 'data:', data, 'message:', message);
          break;

        default:
          console.log('Unhandled Auth event:', event, 'data:', data, 'message:', message);
          break;
      }
    });
  },

  beforeMount()
  {
    this.ready = false;
    Auth.currentSession()
        .then((session) => {
          //console.log('Auth.currentSession():', session);
          this.$store.commit('setUserSession', session);
          this.ready = true;
        })
        .catch((error) => {
          console.log('Auth.currentSession() error:', error);
          this.$store.commit('clearUserSession');
          // Avoid display of Sign In button when we're waiting
          // for a valid signIn event
          setTimeout((app) => {app.ready = true;}, 1000, this);
        });
  },

  methods: {
    signIn: function()
    {
      this.ready = false;
      console.log('Triggering Auth.federatedSignIn()');
      Auth.federatedSignIn()
          .catch(err => console.log('Auth.federatedSignIn error:', err));
    },
    signOut: function()
    {
      this.ready = false;
      console.log('Triggering Auth.signOut()');
      this.$store.commit('clearUserSession');
      Auth.signOut()
          .then(() => {
            // Construct the Cognito Hosted UI URL and redirect there.
            // This user experience is slightly better than using a
            // signIn button, which if used in place of the
            // "Loading ..." text in the template above, appears
            // momentarily, even for logged-in users, until
            // this.$store.signedIn is resolved by an Auth Promise.
            //
            // Turns out that Auth.signOut() doesn't actually sign the
            // user out of Cognito, but this redirect is a reasonable
            // facsimile
            const config = Auth.configure();
            const url = [
              `https://${config.oauth.domain}/login`,
              `?client_id=${config.userPoolWebClientId}`,
              `&response_type=code`,
              `&scope=${config.oauth.scope.join('+')}`,
              `&redirect_uri=${config.oauth.redirectSignOut}`
            ].join('');
            // console.log('Redirecting to Cognito Hosted UI:', url);
            window.location = url;
          })
          .catch(err => console.log('Auth.signOut error:', err));
    },
  },
};
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 10px 20px 0px 20px;
}

a.router-link-active {
  color: #42b983;
  margin: 10px;
}

.signIn {
  margin-top: 50px;
}

.signOut {
}

footer {
  font-size: x-small !important;
}

#signed-in-as
{
  text-align: right;
  line-height: 1.5;
  font-size: 0.85em;
}

.main-content {
  min-height: calc(100vh - 28px);
}
</style>
