// Vuex state store
// See https://vuex.vuejs.org FMI on Vuex

import {createStore} from 'vuex';
import { Auth }  from 'aws-amplify';


export const store = createStore({

   // State store primitive objects. Don't use directly, but through
   // getters, mutations (synchronous), or actions (asynchronous)
   state: {
      userSession: null,
   },

   /**
    * These getters can be accessed by mapping them in components like
    *
    *   import { mapGetters } from 'vuex'
    *   export default {
    *      // Other stuff
    *      computed: {
    *         ...mapGetters(['signedIn', 'userIsRecruiter'])
    *      },
    *      // more stuff
    *   };
    *
    * computed properties can then be used directly in templates or in 
    * javascript by e.g., this.userIsRecruiter.
    */
   getters: {
      userSession: state => {
         return state.userSession;
      },

      userId: state => {
         return state.userSession ?
            state.userSession.idToken.payload['cognito:username'] : null;
      },

      userEmail: state => {
         return state.userSession ?
            state.userSession.idToken.payload['email'] : null;
      },
   
      signedIn: state => {
         return state.userSession ? true : false;
      },

      userIsInAnyGroup: (state, getters) => {
         return getters.signedIn &&
         state.userSession?.idToken?.payload['cognito:groups']?.length;
      },

      UserInGroup: (state) => (group) => {
         //console.log(group, state.userSession, state.userSession.idToken, state.userSession.idToken.payload, state.userSession.idToken.payload['cognito:groups']);
         return state.userSession?.idToken?.payload['cognito:groups']?.includes(group) || false;
      },
      UserInOneOfGroups: (state) => (groups) => {
         groups = groups || [];
         const userGroups = state.userSession?.idToken?.payload['cognito:groups'] || [];
         return groups.filter((group) => userGroups.includes(group)).length > 0;
      }
   },

   // Mutations must be synchronous, and are invoked like
   // this.$store.commit('setUserSession', userSession);
   mutations: {
      setUserSession: (state, userSession) => {
         state.userSession = userSession;
         //console.log('state.userSession:', state.userSession);
         //console.log('state.userSession.idToken:', state.userSession.idToken);
      },
      clearUserSession: (state) => {
         state.userSession = null;
      },
   },

   // Actions can be asynchronous and invoked with
   // this.$store.dispatch('fetchUserSession');
   actions: {
      fetchUserSession({commit}) {
         Auth.currentSession()
          .then(session => {
             console.log("fetchUserSession got session:", session);
             commit('setUserSession', session);
          })
          .catch((err) => {
             console.log("fetchUserSession error:", err);
             commit('clearUserSession')
          });
      },

      /*
      authorizeRequests({commit}, axiosInstance) {
         console.log('Registering interceptor');
         axiosInstance.interceptors.request.use(async (request) => {
            console.log('Request:', request);
            try {
               let userSession = await Auth.currentSession();
               commit('setUserSession', userSession);
               if (userSession)
               {
                  request.headers['Authorization'] = userSession.getIdToken().getJwtToken();
               }
            }
            catch (error) {
               console.log('Error setting Authorization header:', error);
               commit('clearUserSesion');
            }
            console.log('Modified request:', request);
            return request;
         });
      },
      */
   }
});
